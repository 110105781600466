
import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleNotification from "./modules/notification.js"
import moduleMembers from "./modules/members.js"

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    modules: {
        layout: moduleLayout,
        notification: moduleNotification,
        member: moduleMembers
    },
    strict: process.env.NODE_ENV !== 'production',
})

export default store
