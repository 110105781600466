import env from '../env.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
        shortDesc: "Supporters group for Governor Umo Eno",
        appEmail: "support@supportgroup.com"
    },
    scrollY: 0,
    windowWidth: window.innerWidth,
    countries: [],
    localGovernments: [],
    banks:[]
}

export default state
